<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import 'jspdf-autotable'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontBody = ref(13)
    const frame = ref('')

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    if (route.value.query.type == 'opd') {
      prints.printsCertDoctorOpd({
        opd_id: route.value.query.id,
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.orderdetail
        savePDF(res)
      })
    } else {
      prints.printsCertDoctor({
        cert_id: route.value.query.id.split(','),
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.orderdetail
        savePDF(res)
      })
    }
    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      })
      doc.setProperties({
        title: `ใบรับรองแพทย์กรณีผู้ติดเชื้อโควิด 19 ${data.length == 1 ? `${data[0].customer_id} ${data[0].customer_fullname} - ${data[0].opd_date}` : ''}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'normal')
      doc.setFontSize(fontBody.value)

      for (let i = 0; i < data.length; i++) {
        doc.addImage(require('./CertDoctorCovid.jpg'), 'JPEG', 0, 0, 210, 297)

        // doc.text(data[i].cert_code, 167, 22)
        doc.text(data[i].customer_fullname.replaceAll('null', ''), 54, 27)
        doc.text(51, 47.4, data[i].customer_address)

        // doc.text(data[i].customer_address.replaceAll('null', ''), 68, 48.7)

        const idCard = []
        if (data[i].customer_idcard && data[i].customer_idcard.length === 13) {
          for (let a = 0; a < data[i].customer_idcard.length; a++) {
            idCard.push(data[i].customer_idcard[a])
          }
          const idCardHeight = 35.5
          doc.setFontSize(20)
          doc.text(idCard[0], 57.6, idCardHeight, null, null, 'center')
          doc.text(idCard[1], 71.1, idCardHeight, null, null, 'center')
          doc.text(idCard[2], 81.2, idCardHeight, null, null, 'center')
          doc.text(idCard[3], 91.3, idCardHeight, null, null, 'center')
          doc.text(idCard[4], 101.4, idCardHeight, null, null, 'center')
          doc.text(idCard[5], 114.6, idCardHeight, null, null, 'center')
          doc.text(idCard[6], 124.7, idCardHeight, null, null, 'center')
          doc.text(idCard[7], 134.8, idCardHeight, null, null, 'center')
          doc.text(idCard[8], 144.9, idCardHeight, null, null, 'center')
          doc.text(idCard[9], 155, idCardHeight, null, null, 'center')
          doc.text(idCard[10], 167.8, idCardHeight, null, null, 'center')
          doc.text(idCard[11], 177.9, idCardHeight, null, null, 'center')
          doc.text(idCard[12], 191.3, idCardHeight, null, null, 'center')
        }
        doc.setFontSize(fontBody.value)
        const data1 = 124.2
        doc.text(data[i].shop_name, 30, data1)
        doc.text(data[i].opd_date_d, 134, data1)
        doc.text(data[i].opd_date_m, 152, data1)
        doc.text(data[i].opd_date_y, 189, data1)
        const data2 = 131.2
        doc.text(data[i].user_fullname, 34, data2)
        doc.text(data[i].user_license || '', 175, data2)
        const data3 = 138.5
        doc.text(data[i].shop_name, 60, data3)
        doc.text(data[i].customer_fullname.replaceAll('null', ''), 155, data3)

        doc.setFontSize(11)
        doc.text(`(${data[i].user_fullname})`, 150, 271.9, null, null, 'center')
        doc.setFontSize(fontBody.value)
        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)
      frame.value = doc.output('datauristring', { filename: 'hello world' })
    }
    const go = () => {
      window.open(frame.value, '_blank')
    }

    return { savePDF, frame, go }
  },
}
</script>
